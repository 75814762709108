html {
	height: 100% !important;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	height: 100%;
	font-size: 14px;
	color: #363940;
}

body .body {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
}

*:focus {
	outline: none !important;
	box-shadow: none !important;
}

/* Begin Fonts */
body {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
	font-weight: 700;
	margin-bottom: 10px;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

p {
	font: 14px/1.5em "Roboto";
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: 0 0
}

body {
	line-height: 1
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block
}

nav ul {
	list-style: none
}

blockquote,
q {
	quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
	content: '';
	content: none
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: 0 0
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: 700
}

del {
	text-decoration: line-through
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0
}

input,
select {
	vertical-align: middle
}

li,
ol,
ul {
	list-style: none;
}